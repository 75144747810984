import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectService} from './project.service';
import {Cooperate, FORM_TYPE, Project} from '../models';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../authentication/auth.service';
import {Router} from '@angular/router';
import {toggleModalById} from '../utils';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  form: FormGroup;
  projects: Project[];
  error;
  isSubmitted;
  formType;
  currentProjectId;
  selectedCorporate;
  currentOptionMenu;

  searchTerm = '';
  isDataFetched = false;

  FORM_TYPE;

  constructor(private formBuilder: FormBuilder, private projectService: ProjectService, private spinner: NgxSpinnerService,
              private toaster: ToastrService, protected router: Router) {
    this.FORM_TYPE = FORM_TYPE;
  }

  ngOnInit() {
    this.goToHome();
    this.form = this.formBuilder.group({
      // id: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      contactName: [null, Validators.compose([Validators.required])],
      status: [null, Validators.compose([])],
      contactPhoneNumber: [null, Validators.compose([Validators.required])],
      contactWhatsappNumber: [null, Validators.compose([Validators.required])],
      contactEmail: [null, Validators.compose([Validators.required, Validators.email])],
    });

    this.projectService.corporate.subscribe(data => {
      this.selectedCorporate = data;
      this.getProjectsForUser();
    });

  }

  goToHome() {
    if (AuthService.getRole() === 2000) {
      this.router.navigate(['/device', AuthService.getAccount()]);
    }
  }

  getProjectsForUser() {
    this.spinner.show();
    this.projectService.getProjectsForUser(this.selectedCorporate ? this.selectedCorporate.id : null)
      .subscribe(response => {
        this.projects = response.content;
        this.isDataFetched = true;
        this.projects.forEach((e, index) => {
          this.getProjectSummary(index);
        });
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  createProject(entity) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }
    this.error = null;
    this.spinner.show();
    if (this.formType === FORM_TYPE.ADD) {
      entity.corporateId = this.selectedCorporate.id;
      this.projectService.createProject(entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        // this.projects.push(entity);
        this.getProjectsForUser();
        this.toaster.success('Project created successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.statusDescription;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    } else {
      this.projectService.updateProject(this.currentProjectId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        this.getProjectsForUser();
        this.toaster.success('Project updated successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.statusDescription;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }
  }

  addProjectInit() {
    this.formType = FORM_TYPE.ADD;
    this.isSubmitted = false;
    this.form.reset();
  }

  hasPermission() {
    return AuthService.hasProjectCreationAccess() && this.selectedCorporate;
  }

  projectEdit(project: Project) {
    this.currentOptionMenu = null;
    this.isSubmitted = true;
    this.formType = FORM_TYPE.EDIT;
    this.currentProjectId = project.id;
    this.form.controls.name.setValue(project.name);
    this.form.controls.contactName.setValue(project.contactName);
    this.form.controls.contactEmail.setValue(project.contactEmail);
    this.form.controls.contactPhoneNumber.setValue(project.contactPhoneNumber);
    this.form.controls.contactWhatsappNumber.setValue(project.contactWhatsappNumber);
    this.form.controls.status.setValue(project.status);

  }

  goToUserManagement(projectId) {
    this.router.navigate(['/user-management', projectId]);
  }

  optionMenu(project) {
    this.currentOptionMenu = project;
  }

  outsideOptionMenu(project) {
    if (this.currentOptionMenu && project.id === this.currentOptionMenu.id) {
      this.currentOptionMenu = null;
    }
  }

  deleteProject() {
    this.spinner.show();
    this.projectService.deleteProject(this.currentProjectId).subscribe(response => {
      toggleModalById('deleteUser');
      this.spinner.hide();
      this.getProjectsForUser();
      this.toaster.success('Successfully deleted', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }

  getProjectSummary(index) {
    this.projectService.getProjectSummary(this.projects[index].id)
      .subscribe(response => {
        this.projects[index].summary = response.content;
      }, error => {
      }, () => {
      });
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  getProjects() {
      return this.projects.filter(p => p.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
  }
  // return this.projectsObs.subscribe();

}
