import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../project/project.service';
import {AlertHistory, AlertLimit, AlertLimitHistory, Device, User} from '../models';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../authentication/auth.service';
import {toggleModalById} from '../utils';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.css']
})
export class DeviceSettingsComponent implements OnInit {
  deviceId;
  projectId;
  device: Device;
  currentSelectedTab = 0;
  users: User[];

  alerts: AlertLimit[];
  previousAlertValues: string;
  alertsOriginal: AlertLimit[];
  maintenanceHistory: AlertHistory[];
  alertHistory: AlertHistory[];
  alertLimitHistory: AlertLimitHistory[];
  currentOpenAccordion = 0;
  form;

  reason;
  numberOfMaintenanceHistoryPerPage = 10;
  numberOfAlertHistoryPerPage = 10;
  numberOfThresholdHistoryPerPage = 10;
  isReasonSubmitted = false;
  isAllFetchedMaintenance = false;
  isAllFetchedAlert = false;
  isAllFetchedThreshold = false;
  isFetchingDataMaintenance = false;
  isFetchingDataAlert = false;
  isFetchingDataThreshold = false;
  maintenancePage = 0;
  alertPage = 0;
  thresholdPage = 0;


  constructor(private spinner: NgxSpinnerService, private route: ActivatedRoute, private projectService: ProjectService,
              private toaster: ToastrService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.deviceId = params['deviceId'];
      this.projectId = params['projectId'];
    });
    this.getProject();
    this.getDevice();
    this.getUsers();
    this.getMaintenanceHistory(0, this.numberOfMaintenanceHistoryPerPage);
    this.getAlertToggleHistory(0, this.numberOfAlertHistoryPerPage);
  }

  getUsers() {
    this.spinner.show();
    this.projectService.getUsersOfProject(this.projectId)
      .subscribe(response => {
        this.users = response.content;
      }, error => {
        this.spinner.hide();
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
      });
  }

  getMaintenanceHistory(offset: number, entityCount: number, spinner = true) {
    if (spinner) {
      this.spinner.show();
    }
    this.isFetchingDataMaintenance = true;
    this.projectService.getMaintenanceHistory(this.deviceId, offset, entityCount)
      .subscribe(response => {
        if (response.content.length < entityCount) {
          this.isAllFetchedMaintenance = true;
        }
        if (this.maintenanceHistory) {
          this.maintenanceHistory = this.maintenanceHistory.concat(response.content);
        } else {
          this.maintenanceHistory = response.content;
        }
      }, error => {
        this.isFetchingDataMaintenance = false;
        this.spinner.hide();
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
        this.isFetchingDataMaintenance = false;
      });
  }

  getAlertToggleHistory(offset: number, entityCount: number, spinner = true) {
    this.isFetchingDataAlert = true;
    if (spinner) {
      this.spinner.show();
    }
    this.projectService.getAlertToggleHistory(this.deviceId, offset, entityCount)
      .subscribe(response => {
        if (response.content.length < entityCount) {
          this.isAllFetchedAlert = true;
        }
        if (this.alertHistory) {
          this.alertHistory = this.alertHistory.concat(response.content);
        } else {
          this.alertHistory = response.content;
        }
      }, error => {
        this.spinner.hide();
        this.isFetchingDataAlert = false;
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
        this.isFetchingDataAlert = false;
      });
  }

  getAlertLimitForPropertyHistory(offset: number, entityCount: number, spinner = true) {
    if (spinner) {
      this.spinner.show();
    }
    this.isFetchingDataThreshold = true;
    this.projectService.getAlertLimitForPropertyHistory(this.deviceId, this.device.properties[this.currentSelectedTab].number, offset, entityCount)
      .subscribe(response => {
        if (response.content.length < entityCount) {
          this.isAllFetchedThreshold = true;
        }
        if (this.alertLimitHistory) {
          this.alertLimitHistory = this.alertLimitHistory.concat(response.content);
        } else {
          this.alertLimitHistory = response.content;
        }
      }, error => {
        this.spinner.hide();
        this.isFetchingDataThreshold = false;
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
        this.isFetchingDataThreshold = false;
      });
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.projectService.projectNameSource.next(response.content.name);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getDevice() {
    this.spinner.show();
    this.projectService.getDevice(this.projectId, this.deviceId)
      .subscribe(response => {
        this.device = response.content;
        this.projectService.deviceNameSource.next(this.device.name);

        this.getAlertLimitForProperty();
        this.getAlertLimitForPropertyHistory(0, this.numberOfThresholdHistoryPerPage);
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getAlertLimitForProperty() {
    this.spinner.show();

    this.projectService.getAlertLimitForProperty(this.deviceId, this.device.properties[this.currentSelectedTab].number)
      .subscribe(response => {
        this.alertsOriginal = JSON.parse(JSON.stringify(response.content));
        this.alerts = response.content;
        if (this.alerts.length !== this.device.alertLevel) {
          const diff = this.device.alertLevel - this.alerts.length;
          const length = this.alerts.length;
          for (let i = 0; i < diff; i++) {
            this.alerts.push({
              level: length + i + 1,
              low: 0,
              high: 0
            });
            this.alertsOriginal.push({
              level: length + i + 1,
              low: 0,
              high: 0
            });
          }
        }
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  selectTab(index) {
    this.currentSelectedTab = index;
    this.getAlertLimitForProperty();
    this.getAlertLimitForPropertyHistory(0, this.numberOfThresholdHistoryPerPage);
  }

  updateDeviceLimit(alert: AlertLimit) {
    this.spinner.show();
    this.projectService.updateDeviceLimit(this.deviceId, this.device.properties[this.currentSelectedTab].number, {
      level: alert.level,
      low: alert.low,
      high: alert.high,
      currentLevelPeriod: alert.currentLevelPeriod,
      persistence: alert.persistence,
      nextLevelPeriod: alert.nextLevelPeriod
    }).subscribe(response => {
      this.spinner.hide();
      this.alertsOriginal[alert.level - 1].low = alert.low;
      this.alertsOriginal[alert.level - 1].high = alert.high;
      this.getAlertLimitForPropertyHistory(0, this.numberOfThresholdHistoryPerPage);
      // this.getDevice();
      this.toaster.success('Settings updated successfully', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }

  updateMaintenanceStatus(status) {
    this.spinner.show();
    this.projectService.updateMaintenanceStatus(this.deviceId, {
      maintain: status
    }).subscribe(response => {
      this.spinner.hide();
      this.maintenanceHistory = [];
      this.getMaintenanceHistory(0, this.numberOfMaintenanceHistoryPerPage);
      this.toaster.success('Settings updated successfully', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }

  updatePersistenceStatus(status) {
    this.spinner.show();
    this.projectService.updatePersistenceStatus(this.deviceId, {
      persistence: status
    }).subscribe(response => {
      this.device.persistence = !this.device.persistence;
      this.spinner.hide();
      this.toaster.success('Settings updated successfully', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }

  updateAlertStatus(status, level) {
    console.log(status);
    this.spinner.show();
    const alertVar = {};
    alertVar[level] = status;
    this.projectService.updateAlertStatus(this.deviceId, {
      alerts: alertVar
    }).subscribe(response => {
      this.spinner.hide();
      this.getAlertToggleHistory(0, this.numberOfAlertHistoryPerPage);
      this.toaster.success('Settings updated successfully', 'Success');
    }, error => {
      this.spinner.hide();
      this.toaster.error('Oops... Something went wrong', 'Error!');
    });
  }

  getOptions(index) {
    return {
      floor: Math.ceil((this.alertsOriginal[index].low - 20) / 10) * 10,
      ceil: Math.ceil((this.alertsOriginal[index].high + 20) / 10) * 10,
    };
  }

  resetLimit(index) {
    this.alerts[index] = JSON.parse(JSON.stringify(this.alertsOriginal[index]));
  }

  changeOfMaintenance() {
    this.isReasonSubmitted = true;
    if (!this.reason) {
      return;
    }

    toggleModalById('confirm');
    this.isReasonSubmitted = false;
    this.reason = null;
    this.device.maintain = !this.device.maintain;
    this.updateMaintenanceStatus(this.device.maintain);
  }

  changeOfPersistence() {
    toggleModalById('confirmPersistence');
    this.updatePersistenceStatus(!this.device.persistence);
  }

  changeOfAlertLevel(status, level) {
    this.device.alerts[level] = !this.device.alerts[level];
    this.updateAlertStatus(this.device.alerts[level], level);
  }

  accordionSelected(index) {
    this.currentOpenAccordion = index;
  }

  onConfirm() {

  }

  changeOfMaintenanceClick(event) {
    event.preventDefault();
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  isAccountUser() {
    return AuthService.isAccountUser();
  }

  isUserRoleRestricted(): boolean {
    return AuthService.getRole() > 1000;
  }
  

  isButtonActive(i) {
    let active = true;
    Object.getOwnPropertyNames(this.alertsOriginal[i]).forEach(
      alert => {
        if ((this.alertsOriginal[i][alert] !== this.alerts[i][alert]) ||
          (typeof this.alertsOriginal[i]['persistence'] === 'undefined' && typeof this.alerts[i]['persistence'] !== 'undefined')) {
          active = false;
        }
      }
    );
    return active;
  }

  getWidthColumn(containerId: string, percentage: number, reserved: number) {
    const len = document.getElementById(containerId).offsetWidth;
    return (len - reserved) * percentage / 100;
  }

  showThreshold() {
    return this.device && this.device.properties[this.currentSelectedTab].showThreshold;
  }

  loadMoreThresholdHistory(DOMID: string): void {
    if (this.bottomReached(DOMID) && !this.isAllFetchedThreshold) {
      this.getAlertLimitForPropertyHistory(++this.thresholdPage, this.numberOfThresholdHistoryPerPage, false);
    }
  }

  loadMoreMaintenanceHistory(DOMID: string): void {
    if (this.bottomReached(DOMID) && !this.isAllFetchedMaintenance) {
      this.getMaintenanceHistory(++this.maintenancePage, this.numberOfMaintenanceHistoryPerPage, false);
    }
  }

  loadMoreAlertHistory(DOMID: string): void {
    if (this.bottomReached(DOMID) && !this.isAllFetchedAlert) {
      this.getAlertToggleHistory(++this.alertPage, this.numberOfAlertHistoryPerPage, false);
    }
  }

  bottomReached(DOMID: string): boolean {
    const offset = document.getElementById(DOMID).offsetHeight;
    const scrolledHeight = document.getElementById(DOMID).scrollTop;
    return (offset + scrolledHeight + 1) >= document.getElementById(DOMID).scrollHeight;
  }
}
