import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {CustomValidators} from '../../custom-validators';
import {AuthService} from '../../authentication/auth.service';
import {ProjectService} from '../../project/project.service';
import {MetaData} from '../../models';
import {hideProfileMenu, openNavSet, toggleModalById} from '../../utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  form: FormGroup;
  formConfig: FormGroup;
  error;
  username;
  isSubmitted = false;
  isDialog = false;
  metaData: MetaData;
  configurations: any;
  version = 'v2.2.3';

  constructor(private router: Router, private formBuilder: FormBuilder, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private toaster: ToastrService) {
  }

  ngOnInit() {
    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }
    this.form = this.formBuilder.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      validator: CustomValidators.passwordMatchValidator
    });

    this.formConfig = this.formBuilder.group({
      aboutTitle: [],
      aboutUrl: [],
      appName: [],
      emailFooterText: [],
      imageURL: [],
      faviconIconUrl: [],
      hasKitUser: []
    });

    this.router.events.subscribe((val) => {
      openNavSet();
    });

    this.username = AuthService.getUsername();

    this.getMetaData();
    // if (localStorage.getItem('MENU_STATE') === 'OPEN') {
    //   openNavBar();
    // }

    this.projectService.metaDataSource.subscribe(data => {
      this.metaData = data;
      console.log(data);
    });
  }

  logout() {
    AuthService.signOut();
    this.router.navigate(['/login']);
  }

  goToHome() {
    if (AuthService.getRole() >= 10000) {
      this.router.navigate(['/device', 'D_U']);
    } else {
      this.router.navigate(['/']);
    }
  }

  changePassword(value: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    this.spinner.show();
    this.error = null;
    value.username = AuthService.getUsername();
    this.projectService.changePassword(value).subscribe(response => {
      this.spinner.hide();
      toggleModalById('changePassword');
      this.toaster.success('Password changed successfully', 'Success');
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  getConfigurations() {
    this.projectService.getMetaData().subscribe(metaData => {
      const content = metaData.content;
      this.configurations = content.configuration;
      this.formConfig.reset(true);
      this.formConfig.patchValue(this.configurations);
      this.formConfig.controls.hasKitUser.setValue(this.configurations.hasKitUser === 'true');
    });


  }

  saveConfigs(value: any) {
    this.isSubmitted = true;

    if (!this.formConfig.valid) {
      return;
    }

    this.spinner.show();
    const metaData = {configuration: value};
    this.projectService.updateMetaData(metaData).subscribe(response => {
      this.metaData.configuration = value;
      this.projectService.metaDataSource.next(this.metaData);
      this.spinner.hide();
      toggleModalById('changeConfig');
      // this.projects.push(entity);
      this.toaster.success('Configuration updated successfully', 'Success');
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.statusDescription;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  onClickedOutside(event) {
    hideProfileMenu();
  }

  onNavigateNotification() {
    this.router.navigate(['/notification']);
  }

  menuStateChange() {
    let state;
    if (localStorage.getItem('MENU_STATE')) {
      state = localStorage.getItem('MENU_STATE');
    } else {
      state = 'OPEN';
    }

    if (state === 'OPEN') {
      localStorage.setItem('MENU_STATE', 'OPEN');
    } else {
      localStorage.setItem('MENU_STATE', 'CLOSE');
    }
  }

  hasPermission() {
    return AuthService.hasCorporateMenuAccess();
  }

  goToRouterApp() {
    window.open('http://3.130.61.117:8080/demo', '_blank');
  }

  getMetaData() {
    this.projectService.getMetaData()
      .subscribe(response => {
        this.projectService.metaDataSource.next(response.content);

        if (AuthService.getRole() === 1000) {
          this.projectService.corporateSource.next({name: response.content.heading});
        }
      }, error => {
      }, () => {
      });
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  isAccountUser() {
    return AuthService.isAccountUser();
  }
}
