import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../project/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NotificationPolar } from '../models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  emailAckId: string | null = null; // Variable to store emailAckId

  notifications: NotificationPolar[] = [];
  error;
  page = 0;
  isAllRetrieved = false;
  isFetchingData = false;
  
  isPopupVisible = false; // Popup visibility state
  selectedNotification: NotificationPolar | null = null; // Stores the selected notification details

  name: string = '';
  kitId: string = '';
  message: string = '';
  status: string ='';
  email: string = ''; 
  userId: string = "";
  

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private toaster: ToastrService, private http: HttpClient) {}

  ngOnInit() {
    this.spinner.show();
    this.getNotification(0);
  }

  getNotification(offset: number): void {
    this.isFetchingData = true;
    this.projectService.getNotificationForUser(offset).subscribe({
      next: (response) => {
        if (response.content.length < 10) {
          this.isAllRetrieved = true;
        }
        this.notifications = this.notifications.concat(response.content);
      },
      error: () => {
        this.spinner.hide();
        this.isFetchingData = false;
        this.toaster.error('Oops... Something went wrong', 'Error!');
      },
      complete: () => {
        this.spinner.hide();
        this.isFetchingData = false;
      }
    });
  }

  loadMoreAtEnd(e): void {
    if (this.bottomReached() && !this.isAllRetrieved) {
      this.getNotification(++this.page);
    }
  }

  bottomReached(): boolean {
    const element = document.getElementById('main3');
    if (!element) return false;

    const offset = element.offsetHeight;
    const scrolledHeight = element.scrollTop;
    return (offset + scrolledHeight + 1) >= element.scrollHeight;
  }

  // Function to open the popup
  openPopup(emailAckId: string, notification: NotificationPolar) {
    this.selectedNotification = notification; // Set the selected notification
    this.emailAckId = emailAckId;
    this.fetchNotificationDetails();
    this.isPopupVisible = true; // Show the popup
  }

  // Function to close the popup
  closePopup(): void {
    this.isPopupVisible = false; // Hide the popup
    this.selectedNotification = null; // Clear the selected notification
  }

  fetchNotificationDetails(): void {
    if (this.emailAckId) {
      const apiUrl = environment.host + `/emailAck/${this.emailAckId}/get-email-ack`;

      this.http.get<any>(apiUrl).subscribe({
        next: (response) => {
          console.log('API Response:', response);
          this.name = response.name || '';
          this.kitId = response.content.kitId || '';
          this.message = response.content.message || '';
          this.status = response.content.status || '';
          this.email = response.content.email || ''; 
          this.userId = response.content.userId || "";
          
        },
        
        error: (error) => {
          console.error('Error fetching notification details:', error);
          this.toaster.error('Failed to fetch notification details. Please try again later.');
        }
      });
    } else {
      console.error('emailAckId is not defined, unable to fetch notification details');
    }
  }
}
