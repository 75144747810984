import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {Device, FORM_TYPE, MagmaResponse, User} from '../models';
import {USER_ROLES} from '../enums';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../authentication/auth.service';
import {Location} from '@angular/common';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {toggleModalById} from '../utils';

interface Member {
  name: string;
  email: string;
  role: Number;
  id: Number;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  projectId;
  corporateId;
  users: User[];
  kits: Device[];
  filteredUsers: User[] = null;
  form: FormGroup;
  currentSelectedUserId;
  selectedExistsEmail: string;
  selectedEmailsId;
  error;
  isSubmitted;
  isLoading;
  formType = FORM_TYPE.ADD;
  numberOfLevels;
  errorAlert;

  FORM_TYPE;
  selectedCorporate: any;
  members: { name: string; email: string; role: number; id: number }[];

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private toaster: ToastrService, private location: Location) {
    this.FORM_TYPE = FORM_TYPE;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
    });

    this.getProject();
    this.getUsers();

    this.form = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')])],
      lastName: [null, Validators.compose([Validators.pattern('^[a-zA-Z](?:[a-zA-Z ]||[0-9])*$'), Validators.required])],
      // password: [null, Validators.compose([Validators.required])],
      mobile: [null, Validators.compose([Validators.required])],
      whatsApp: [null, Validators.compose([Validators.required, Validators.pattern(/^(?:\+|0)[1-9]\d{6,14}$/)])],
      status: [null, Validators.compose([])],
      userRoleId: [null, Validators.compose([Validators.required])],
    });
    this.form.get('email').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.projectService.filterUser(1000, value !== this.selectedExistsEmail ? value : '')
        .pipe(
          finalize(() => (this.isLoading = false))
        )
      )
    ).subscribe(users => {
      if (this.formType === FORM_TYPE.ADD) {
        this.filteredUsers = users.content;
        if (this.form.get('email').value !== this.selectedExistsEmail) {
          this.form.get('lastName').enable();
          this.form.get('mobile').enable();
          this.form.get('whatsApp').enable();
          this.form.get('userRoleId').enable();
          this.form.get('userRoleId').setValue('');
          this.form.get('lastName').setValue('');
          this.form.get('mobile').setValue('');
          this.form.get('whatsApp').setValue('');
          this.selectedExistsEmail = '';
        }
      }

    });

  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.numberOfLevels = response.content.maintainLevel;
        this.projectService.projectNameSource.next(response.content.name);
        if (response.content && response.content.corporateId) {
          this.selectedCorporate = response.content.corporateId;
          console.log('Selected Corporate ID:', this.selectedCorporate);
        }
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getUsers() {
    this.spinner.show();
    this.projectService.getUsersOfProject(this.projectId)
      .subscribe(response => {
        this.users = response.content;
      }, error => {
        this.spinner.hide();
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }, () => {
        this.spinner.hide();
      });
  }

  getKitsOfUsers(userId) {
    this.spinner.show();
    this.projectService.getKitsOfProjectForUser(userId, this.projectId)
      .subscribe(response => {
        this.kits = response.content;
        this.kits.forEach(kit => {
          if (!this.errorAlert) {
            this.getUserAlert(kit, userId);
          } else {
            this.spinner.hide();
          }
        });
      }, error => {
        this.spinner.hide();
        this.toaster.error('Oops... Something went wrong', 'Error!');
      });
  }

  getUserAlert(kit: Device, userId) {
    this.spinner.show();
    this.projectService.getUserAlert(kit.id, userId)
      .subscribe(response => {
        kit.alertStatus = response.content;
      }, error => {
        this.spinner.hide();
        if (error.status !== 422) {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        } else {
          this.errorAlert = error.error.message;
        }
      }, () => {
        this.spinner.hide();
      });
  }

  getModes(modes) {
    if (!modes) {
      return [];
    }
    return Object.getOwnPropertyNames(modes);
  }

  showEntityListModal(userId) {
    this.errorAlert = null;
    this.currentSelectedUserId = userId;
    this.getKitsOfUsers(userId);
  }

  resendRegistrationMail() {
    const selectedUser = this.users.find(item => {
      return item.id === this.currentSelectedUserId;
    });

    this.spinner.show();
    this.projectService.resendRegistrationMail({
      host: window.location.protocol + '//' + window.location.host + '/sign-up',
      email: selectedUser.email
    }).subscribe(response => {
      this.spinner.hide();
      toggleModalById('resendRegistrationMail');
      this.toaster.success('A mail has been sent again. Please confirm within 24 hours', 'Success');
    }, error => {
      this.spinner.hide();
      if (error && error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  createUser(entity) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    this.spinner.show();
    this.error = null;
    if (this.formType === FORM_TYPE.ADD) {
      let message: string;
      if (this.selectedExistsEmail !== '' && this.selectedExistsEmail === entity.email) {
        entity = {id: this.selectedEmailsId, userRoleId: entity.userRoleId};
        message = 'User has been added successfully';
      } else {
        message = 'A mail has been sent. Please confirm within 24 hours';
      }
      entity.host = window.location.protocol + '//' + window.location.host + '/sign-up';
      entity.type = 'SERVER';
      entity.userRoleId = +entity.userRoleId;

      this.projectService.createUser(this.projectId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        entity.userRoleId = +entity.userRoleId;
        // this.users.push(entity);
        this.getUsers();
        this.toaster.success(message, 'Success');
      }, error => {
        this.spinner.hide();
        if (error && error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
      this.selectedExistsEmail = '';
    } else {
      this.projectService.updateUser(this.projectId, this.currentSelectedUserId, entity).subscribe(response => {
        this.spinner.hide();
        toggleModalById('addNew');
        this.getUsers();
        this.toaster.success('User updated successfully', 'Success');
      }, error => {
        this.spinner.hide();
        if (error && error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }
  }

  fillEmailDetails(user) {
    this.form.patchValue(user);
    this.form.get('lastName').disable();
    this.form.get('mobile').disable();
    this.form.get('whatsApp').disable();
    this.form.get('userRoleId').disable();
    this.selectedExistsEmail = user['email'];
    this.selectedEmailsId = user['id'];
    this.filteredUsers = null;
  }

  getLevelName(level) {
    return USER_ROLES.find((element) => {
      return element.userRoleId === level;
    }).roleName;
  }

  hasUserCreationAccess() {
    return AuthService.hasUserCreationAccess();
  }

  addUserInit() {
    this.isSubmitted = false;
    this.form.reset();
    this.formType = FORM_TYPE.ADD;
    this.error = null;

    if (!this.form.contains('userRoleId')) {
      this.form.addControl('userRoleId', new FormControl(Validators.compose([Validators.required])));
    }

    this.form.get('lastName').enable();
    this.form.get('mobile').enable();
    this.form.get('whatsApp').enable();
    this.form.get('userRoleId').enable();
  }

  hasUserRoleAccess() {
    return AuthService.getRole() !== 2000;
  }

  deleteUser() {
    this.spinner.show();
    this.projectService.deleteUser(this.projectId, this.currentSelectedUserId).subscribe(response => {
      toggleModalById('deleteUser');
      this.spinner.hide();
      this.getUsers();
      this.toaster.success('Successfully deleted', 'Success');
    }, error => {
      this.spinner.hide();
      if (error && error.status === 422) {
        this.toaster.error(error.error.message, 'Error!');
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  userEdit(user) {
    this.currentSelectedUserId = user.id;
    this.formType = FORM_TYPE.EDIT;
    this.form.controls.lastName.setValue(user.lastName);
    this.form.controls.email.setValue(user.email);
    this.form.controls.mobile.setValue(user.mobile);
    this.form.controls.whatsApp.setValue(user.whatsApp);
    this.form.controls.status.setValue(user.status);

    this.form.get('lastName').enable();
    this.form.get('mobile').enable();
    this.form.get('whatsApp').enable();
    this.form.get('userRoleId').enable();

    this.form.removeControl('userRoleId');
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  counter(i: number) {
    return new Array(i);
  }

  updateStatus() {
    this.spinner.show();
    this.kits.forEach(kit => {
      this.setUserAlert(kit.id, kit.alertStatus);
    });
    toggleModalById('entities');
    this.toaster.success('Alerts updated successfully', 'Success');
    this.spinner.hide();
  }

  setUserAlert(kitId, body) {
    this.projectService.updateAlertStatusForKitUser(kitId, this.currentSelectedUserId, body).subscribe(response => {
      // this.spinner.hide();
      // toggleModalById('entities');
      // this.toaster.success('User updated successfully', 'Success');
    }, error => {
      // this.spinner.hide();
      // if (error && error.status === 422) {
      //   this.error = error.error.message;
      // } else {
      //   this.toaster.error('Oops... Something went wrong', 'Error!');
      // }
    });
  }

  getCorporateMembers() {
    console.log("Fetching corporate members for project:");
  
    this.spinner.show();
  
    this.projectService.getExistingProjectUsers(this.selectedCorporate, this.projectId)
      .subscribe(
        (response: MagmaResponse<User[]>) => {
          this.spinner.hide(); 
  
          this.members = response.content.map(member => ({
            name: member.lastName,
            email: member.email,
            role: member.userRoleId,
            id: member.id,
          }));
        },
        (error) => {
          console.error('Error fetching corporate members:', error);
          this.spinner.hide(); 
        }
      );
  }
  
  filterMembers(searchTerm: string) {
    if (!searchTerm) {
      return this.members;
    }
  
    return this.members.filter(member =>
      member.name.toLowerCase().includes(searchTerm.toLowerCase())  // Filter by name, case-insensitive
    );
  }

  selectedMembers: Member[] = [];
  dropdownOpen = false;
  searchTerm = '';

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  isSelected(member: Member): boolean {
    return this.selectedMembers.some(selected => selected.id === member.id);
  }

  toggleSelection(member: Member) {
    if (this.isSelected(member)) {
      this.selectedMembers = this.selectedMembers.filter(
        selected => selected.id !== member.id
      );
    } else {
      this.selectedMembers.push(member);
    }
  }

  toggleSelectAll() {
    if (this.selectedMembers.length === this.members.length) {
      this.selectedMembers = [];
    } else {
      this.selectedMembers = this.selectedMembers.concat(this.members);
    }
  }
  
  onSave() {
    this.dropdownOpen = !this.dropdownOpen;
    console.log("cdscscsd", this.projectId);
    const selectedIds = this.selectedMembers.map(member => member.id.toString());
    console.log('Selected Member IDs (as strings):', selectedIds);
    this.projectService.addExistingUser(selectedIds, this.projectId).subscribe(response => {
      this.getUsers();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
}