import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {MetaData, Project} from '../models';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../authentication/auth.service';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import {toggleModalById} from '../utils';

@Component({
  selector: 'app-project-setting',
  templateUrl: './project-setting.component.html',
  styleUrls: ['./project-setting.component.css']
})
export class ProjectSettingComponent implements OnInit {
  projectId;
  project: Project;
  error;
  form: FormGroup;
  timeThresholdControl: FormControl;
  offlineCountControl: FormControl;
  maxNoOfAlertsControl: FormControl;
  presentValues = {};

  alertLevel;
  maintainLevel;

  isActiveButton = false;
  metaData: MetaData;

  constructor(private route: ActivatedRoute, private toaster: ToastrService, private projectService: ProjectService,
              private spinner: NgxSpinnerService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['projectId'];
    });

    this.getProject();


    this.projectService.metaDataSource.subscribe(data => {
      this.metaData = data;
    });

    this.form = this.formBuilder.group({
      // id: [null, Validators.compose([Validators.required])],
      100: [null, Validators.compose([Validators.required])],
      1000: [null, Validators.compose([Validators.required])],
      2000: [null, Validators.compose([])],
      3000: [null, Validators.compose([])],
      4000: [null, Validators.compose([])],
      5000: [null, Validators.compose([])],
      6000: [null, Validators.compose([])],
      10000: [null, Validators.compose([Validators.required])]
    });
    this.timeThresholdControl = new FormControl([null, Validators.compose([])]);
    this.offlineCountControl = new FormControl([null, Validators.compose([])]);
    this.maxNoOfAlertsControl = new FormControl([null, Validators.compose([])]);
  }

  getProject() {
    this.spinner.show();
    this.projectService.getProject(this.projectId)
      .subscribe(response => {
        this.project = response.content;
        this.projectService.projectNameSource.next(response.content.name);
        this.timeThresholdControl.setValue(this.project.dataInterval);
        this.offlineCountControl.setValue(this.project.offlineCount);
        this.maxNoOfAlertsControl.setValue(this.project.maxNoOfAlerts);
        this.presentValues['timeThreshold'] = this.project.dataInterval;
        this.presentValues['offlineCount'] = this.project.offlineCount;
        this.presentValues['maxNoOfAlerts'] = this.project.maxNoOfAlerts;
        console.log(' this.project.dataInterval ' + this.project.dataInterval);
        this.form.controls['1000'].setValue(this.project.userAlertLevel['1000'] ? this.project.userAlertLevel['1000'] : 0);
        this.form.controls['10000'].setValue(this.project.userAlertLevel['10000'] ? this.project.userAlertLevel['10000'] : 0);
        this.form.controls['100'].setValue(this.project.userAlertLevel['100'] ? this.project.userAlertLevel['100'] : 0);
        this.presentValues['1000'] = this.project.userAlertLevel['1000'] ? this.project.userAlertLevel['1000'] : 0;
        this.presentValues['10000'] = this.project.userAlertLevel['10000'] ? this.project.userAlertLevel['10000'] : 0;
        this.presentValues['100'] = this.project.userAlertLevel['100'] ? this.project.userAlertLevel['100'] : 0;

        for (let i = 0; i < response.content.maintainLevel; i++) {
          this.form.controls[(i + 2) * 1000 + ''].setValue(this.project.userAlertLevel[(i + 2) * 1000] ? this.project.userAlertLevel[(i + 2) * 1000] : 0);
          this.presentValues[(i + 2) * 1000 + ''] = this.project.userAlertLevel[(i + 2) * 1000] ? this.project.userAlertLevel[(i + 2) * 1000] : 0;
        }
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  updateProject(project, mode) {
    this.spinner.show();
    this.projectService.updateProject(this.projectId, project).subscribe(response => {
      this.spinner.hide();
      if (mode === 1) {
        toggleModalById('addNew');
      } else if (mode === 2) {
        toggleModalById('addNew1');
      }
      this.toaster.success('Project settings updated successfully', 'Success');
      if (mode !== 0) {
        this.getProject();
      }
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  changeAlertLevel(level) {
    this.alertLevel = level;
  }

  changeMaintainLevel(level) {
    this.maintainLevel = level;
  }

  array(n: number): any[] {
    return Array(n);
  }

  saveSetting(value) {
    const formattedValue = {};
    Object.keys(value).filter(e => {
      if (value[e]) {
        formattedValue[e] = value[e];
        this.presentValues[e] = value[e];
      }
    });
    this.presentValues['timeThreshold'] = this.timeThresholdControl.value;
    this.updateProject({userAlertLevel: formattedValue, dataInterval: this.timeThresholdControl.value, offlineCount: this.offlineCountControl.value, maxNoOfAlerts: this.maxNoOfAlertsControl.value}, 0);
    this.isActiveButton = false;
  }

  submitUserLevel() {
    this.updateProject({maintainLevel: this.maintainLevel}, 1);
  }

  submitAlertLevel() {
    this.updateProject({alertLevel: this.alertLevel}, 2);
  }

  activeButton() {
    let active = false;
    Object.getOwnPropertyNames(this.presentValues).forEach(
      key => {
        if (this.presentValues[key] != this.form.value[key]) {
          active = true;
        }
      }
    );
    if (this.timeThresholdControl.value != this.presentValues['timeThreshold']
      || this.offlineCountControl.value != this.presentValues['offlineCount']
      || this.maxNoOfAlertsControl.value != this.presentValues['maxNoOfAlerts']) {
      active = true;
    }
    this.isActiveButton = active;
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  initModal() {
    this.error = null;
  }
}
