import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {
  Cooperate,
  Device,
  GraphEntity,
  MagmaResponse,
  AlertHistory,
  User,
  AlertLimit,
  AlertLimitHistory,
  AlertStatus,
  NotificationPolar, ProjectSummary, MetaData, Geo, UserAction
} from '../models';
import {AuthService} from '../authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projectNameSource = new BehaviorSubject('');
  projectName = this.projectNameSource.asObservable();

  deviceNameSource = new BehaviorSubject('');
  deviceName = this.deviceNameSource.asObservable();

  corporateSource = new BehaviorSubject<any>('');
  corporate = this.corporateSource.asObservable();

  metaDataSource = new BehaviorSubject<any>('');
  logo = this.metaDataSource.asObservable();

  constructor(protected http: HttpClient) {
  }

  getAccount(accountId): Observable<any> {
    return this.http.get<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/account/' + accountId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getProjectsForUser(corporateId = null): Observable<any> {
    return this.http.get<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project' + (corporateId != null ? '?corporate=' + corporateId : ''),
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getProject(projectId): Observable<any> {
    return this.http.get<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getCorporatesForAccount(): Observable<MagmaResponse<Cooperate[]>> {
    return this.http.get<MagmaResponse<Cooperate[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/account/' + AuthService.getAccount() + '/corporate',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getCorporates(): Observable<MagmaResponse<Cooperate[]>> {
    return this.http.get<MagmaResponse<Cooperate[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getDevicesOfProject(projectId): Observable<MagmaResponse<Device[]>> {
    return this.http.get<MagmaResponse<Device[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/kit',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getGeo(kitId, fromDate, toDate): Observable<MagmaResponse<Geo[]>> {
    return this.http.get<MagmaResponse<Geo[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + kitId + '/geo?from=' + fromDate + '&to=' + toDate,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getProjectSummary(projectId): Observable<MagmaResponse<ProjectSummary>> {
    return this.http.get<MagmaResponse<ProjectSummary>>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/summary',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getUsersOfProject(projectId): Observable<MagmaResponse<User[]>> {
    return this.http.get<MagmaResponse<User[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/system-user',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getKitsOfUsers(userId): Observable<MagmaResponse<Device[]>> {
    return this.http.get<MagmaResponse<Device[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/system-user/' + userId + '/kit',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  addExistingUser(userIds: string[], projectId: string): Observable<MagmaResponse<User[]>> {
    console.log('addExistingUser called with:', userIds, projectId); // Debugging
    return this.http.post<MagmaResponse<User[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId,
      userIds,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + AuthService.getAccessToken()
        })
      }
    );
  }
  getKitsOfProjectForUser(userId, projectId): Observable<MagmaResponse<Device[]>> {
    return this.http.get<MagmaResponse<Device[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/system-user/' + userId + '/kit?project=' + projectId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getUsersOfCorporate(corporateId): Observable<MagmaResponse<User[]>> {
    return this.http.get<MagmaResponse<User[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateId + '/system-user',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getExistingProjectUsers(corporateId, projectId): Observable<MagmaResponse<User[]>> {
    return this.http.get<MagmaResponse<User[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateId + '/project/' + projectId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getNotificationForUser(offset: number): Observable<MagmaResponse<NotificationPolar[]>> {
    return this.http.get<MagmaResponse<NotificationPolar[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/notification/size/10/index/' + offset,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getNotificationOfProject(projectId): Observable<MagmaResponse<NotificationPolar[]>> {
    return this.http.get<MagmaResponse<NotificationPolar[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/notification/size/10/index/0',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getUsersOfKit(kitId): Observable<MagmaResponse<User[]>> {
    return this.http.get<MagmaResponse<User[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + kitId + '/system-user',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getMetaData(): Observable<MagmaResponse<MetaData>> {
    return this.http.get<MagmaResponse<MetaData>>(
      environment.host + '/user/' + AuthService.getUserId() + '/data',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getUserAlert(kitId, userId): Observable<MagmaResponse<AlertStatus>> {
    return this.http.get<MagmaResponse<AlertStatus>>(
      environment.host + '/user/' + AuthService.getUserId() + '/system-user/' + userId + '/kit/' + kitId + '/alert',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getDevice(projectId, deviceId): Observable<MagmaResponse<Device>> {
    return this.http.get<MagmaResponse<Device>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getMaintenanceHistory(deviceId, offset: number, entityCount: number): Observable<MagmaResponse<AlertHistory[]>> {
    return this.http.get<MagmaResponse<AlertHistory[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/maintain/size/' + entityCount + '/index/' + offset,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + AuthService.getAccessToken()
        })
      }
    );
  }

  getAlertToggleHistory(deviceId, offset: number, entityCount: number): Observable<MagmaResponse<AlertHistory[]>> {
    return this.http.get<MagmaResponse<AlertHistory[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/alert/size/' + entityCount + '/index/' + offset,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getAlertLimitForProperty(deviceId, propertyId): Observable<MagmaResponse<AlertLimit[]>> {
    return this.http.get<MagmaResponse<AlertLimit[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/property/' + propertyId + '/alert-limit',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  // tslint:disable-next-line:max-line-length
  getAlertLimitForPropertyHistory(deviceId, propertyId, offset: number, entityCount: number): Observable<MagmaResponse<AlertLimitHistory[]>> {
    return this.http.get<MagmaResponse<AlertLimitHistory[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/property/' + propertyId +
      '/alert-limit/history/size/' + entityCount + '/index/' + offset,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getDeviceHistory(projectId, deviceId, from, to, factor): Observable<MagmaResponse<GraphEntity[]>> {
    return this.http.get<MagmaResponse<GraphEntity[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/property/' + factor + '?from=' + from + '&to=' + to,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getUserActionHistory(userId, offset, page): Observable<MagmaResponse<UserAction[]>> {
    return this.http.get<MagmaResponse<UserAction[]>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + userId + '/history?size=' + offset +
      '&page=' + page + '&order=DESC',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createProject(value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateProject(projectId, value: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId,
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  // updateProject(projectId, value: any): Observable<any> {
  //   return this.http.put<any>(
  //     environment.host + '/user/' + AuthService.getUserId() + '/system-user/' + userId + '/kit/' + kitId + '/alert',
  //
  //     value,
  //     {
  //       headers: new HttpHeaders(
  //         {
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
  //         }),
  //     }
  //   );
  // }

  createCorporate(value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateCorporate(corporateId, value: any): Observable<any> {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateId,
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateMetaData(metaData: any): Observable<MagmaResponse<MetaData>> {
    return this.http.post<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/account/' + AuthService.getAccount() + '/data',
      metaData,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  deleteCorporate(corporateId): Observable<any> {
    return this.http.delete<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  createUser(projectId, value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/system-user',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  resendRegistrationMail(value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/resend-key',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  createUserForCorporate(corporateId, value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateId + '/system-user',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  createUserForKit(kitId, value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + kitId + '/system-user',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  changePassword(value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/change-password',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  createDevice(projectId, entity: any) {
    return this.http.post<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/kit',
      entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateDevice(projectId, kitId, entity: any) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + kitId,
      entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateDeviceLimit(deviceId, propertyId, entity: any) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/property/' + propertyId + '/alert-limit',
      entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateMaintenanceStatus(deviceId, entity: any) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/maintain',
      entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updatePersistenceStatus(deviceId, entity: any) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/persistence',
      entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }


  updateAlertStatus(deviceId, entity: any) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + deviceId + '/alert',
      entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateAlertStatusForKitUser(deviceId, userId, entity: any) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/system-user/' + userId + '/kit/' + deviceId + '/alert',
      entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  forgotPassword(entity: any) {
    return this.http.post<any>(
      environment.host + '/request-reset-password', entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json'
          }),
      }
    );
  }

  filterUser(roleId, email): Observable<MagmaResponse<User[]>> {
    if (email === '') {
      email = null;
    }
    return this.http.get<MagmaResponse<User[]>>(
      environment.host + '/user/' + AuthService.getUserId() + '/system-user/' + roleId + '/search?email=' + email,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  register(entity: any) {
    return this.http.post<any>(
      environment.host + '/user-name-password', entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  verifyForgotPassword(entity: any) {
    return this.http.post<any>(
      environment.host + '/verify-forgot-password', entity,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  reset(body: any) {
    return this.http.post<any>(
      environment.host + '/reset-password', body,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  deleteUser(projectId, userId) {
    return this.http.delete<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/system-user/' + userId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  deleteCorporateUser(corporateId, userId) {
    return this.http.delete<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateId + '/system-user/' + userId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  deleteProject(projectId) {
    return this.http.delete<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  deleteDevice(projectId, deviceId) {
    return this.http.delete<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/kit/' + deviceId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  deleteUserForKit(kitId, userId) {
    return this.http.delete<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + kitId + '/system-user/' + userId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  updateUser(projectId, userId, user) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId + '/system-user/' + userId,
      user,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  updateUserForCorporate(corporateId, userId, user) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateId + '/system-user/' + userId,
      user,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  updateUserForKit(kitId, userId, user) {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/kit/' + kitId + '/system-user/' + userId,
      user,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          }),
      }
    );
  }

  setProjectNameById(projectId) {
    this.http.get<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/project/' + projectId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    ).subscribe(res => this.projectNameSource.next(res.content.name));
  }

  //
  // setCorporateNameById(corpId) {
  //   this.http.get<any>(
  //     environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corpId,
  //     {
  //       headers: new HttpHeaders(
  //         {
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + AuthService.getAccessToken()
  //         })
  //     }
  //   ).subscribe(res => this.projectNameSource.next(res.content.name));
  // }
createProperties(kitId,value: any): Observable<any>{
  return this.http.post<any>(
      environment.host +'/core/kit/'+kitId+'/properties',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()

          }),
      }
  );
}
}
